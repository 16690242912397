// Copyright © 2021 Move Closer
import { __decorate } from "tslib";
import { AppTheme } from '@d24/modules/front';
import { Injectable, mapModel } from '@movecloser/front-core';
import { log } from '@support/log';
import { Site } from './site';
import { siteAdapterMap } from './site.adapter';
import { SubscriptionPeriod } from '@subscription/contracts';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SiteService = class SiteService {
    constructor() {
        this.activeSite = 1;
        this.taboolaInitialLoad = false;
        this.taboolaPusherInitialLoad = false;
        this.consentManagerLoaded = false;
        this.faqUrl = null;
        this.sites = [];
        this.adsConfig = {
            overrides: {},
            ads: {
                ads: true,
                wtg: true,
                taboola: true,
                onNetwork: true,
                stickyAd: true,
                openMarket: true,
                autoHide: false,
                adReloadAll: 'false',
                doNotShrinkCount: 0,
                videoSettings: {
                    youtube: 1,
                    hls: 0,
                    on: 0
                }
            }
        };
        this.welcomeFormConfig = {};
        this.articlePrices = {};
        this.promoCodes = {};
        this.siteImage = '';
        this.selectedAbTest = null;
        this.abTests = {
            enabled: false,
            ratios: {}
        };
    }
    // private Sentry: ISentry | null = null
    // constructor () {
    // if (isNonDevelopmentEnv()) {
    //   this.initSentry()
    // }
    // }
    // private async initSentry () {
    //   this.Sentry = await getSentry()
    // }
    getActiveSite() {
        if (!this.sites.length) {
            throw new Error('[SiteService]: Missing sites dictionary.');
        }
        const foundSite = this.getSiteById(this.activeSite);
        if (foundSite) {
            return foundSite;
        }
        log(`[SiteService]: Site with id ${this.activeSite} not found, falling back to first: ${this.sites[0].name} [${this.sites[0].id}]`, 'warn');
        // if (this.Sentry) {
        //   this.Sentry.captureException(new Error(`[SiteService]: Site with id ${this.activeSite} not found, falling back to first: ${this.sites[0].name} [${this.sites[0].id}]`))
        // }
        return this.sites[0];
    }
    getActiveSiteMeta() {
        const activeSite = this.getActiveSite();
        const meta = [];
        if (activeSite.properties.twitterSite && typeof activeSite.properties.twitterSite === 'string') {
            meta.push({
                property: 'twitter:site',
                content: activeSite.properties.twitterSite
            });
        }
        if (activeSite.properties.googleSiteVerification && typeof activeSite.properties.googleSiteVerification === 'string') {
            meta.push({
                name: 'google-site-verification',
                content: activeSite.properties.googleSiteVerification
            });
        }
        if (activeSite.properties.facebookDomainVerification && typeof activeSite.properties.facebookDomainVerification === 'string') {
            meta.push({
                name: 'facebook-domain-verification',
                content: activeSite.properties.facebookDomainVerification
            });
        }
        if (activeSite.properties.siteName && typeof activeSite.properties.siteName === 'string') {
            meta.push({
                property: 'og:site_name',
                content: activeSite.properties.siteName
            });
        }
        return meta;
    }
    /**
     * @inheritDoc
     */
    getActiveSiteTheme() {
        const activeSite = this.getActiveSite();
        if (!activeSite.properties.theme || typeof activeSite.properties.theme !== 'string') {
            return AppTheme.Default;
        }
        return activeSite.properties.theme;
    }
    getFaqUrl() {
        return this.faqUrl;
    }
    getPolicyPath() {
        const activeSite = this.getActiveSite();
        if (!activeSite.properties.policyPagePath || typeof activeSite.properties.policyPagePath !== 'string') {
            return '';
        }
        return activeSite.properties.policyPagePath;
    }
    getSiteById(id) {
        return this.sites.find(s => s.id === id);
    }
    getSiteByName(name) {
        return this.sites.find(s => s.name === name);
    }
    /**
     * @inheritDoc
     */
    getSites() {
        return this.sites;
    }
    getAdsConfig() {
        return this.adsConfig;
    }
    getVideoSettings() {
        return this.adsConfig.ads.videoSettings;
    }
    getArticlePrices() {
        return this.articlePrices;
    }
    getPromoCodesConfig() {
        return this.promoCodes;
    }
    getWelcomeFormConfig() {
        return this.welcomeFormConfig;
    }
    getSiteImage() {
        return this.siteImage;
    }
    setActive(id) {
        // if (this.Sentry) {
        //   this.Sentry.addBreadcrumb({
        //     category: 'setup',
        //     type: 'debug',
        //     message: '[SiteService] Set active site',
        //     level: this.Sentry.Severity.Debug,
        //     data: {
        //       activeSite: id,
        //       previousActiveSite: this.activeSite
        //     }
        //   })
        // }
        log('🏷 SiteService.setActive()', 'debug');
        this.activeSite = id;
        log(['this.activeSite:', this.activeSite], 'debug');
        log(['this.getActiveSite():', JSON.stringify(this.getActiveSite())], 'debug');
    }
    setFaqUrl(faqUrl) {
        this.faqUrl = faqUrl;
    }
    setSites(sites, searchLabels) {
        for (const payload of Object.values(sites)) {
            this.sites.push(Site.hydrate(mapModel({
                ...payload,
                searchLabels: searchLabels
            }, siteAdapterMap)));
        }
    }
    setAdsConfig(config) {
        this.adsConfig = config;
    }
    setArticlePrices(config) {
        this.articlePrices = config;
    }
    setPromoCodesConfig(config) {
        this.promoCodes = config;
    }
    setAdsOverridesConfigForUser(me = null, subscription = null) {
        var _a, _b, _c, _d;
        const config = this.getAdsConfig();
        if (me) {
            if (subscription && (subscription === null || subscription === void 0 ? void 0 : subscription.subscription) !== null) {
                if (((_a = subscription === null || subscription === void 0 ? void 0 : subscription.subscription) === null || _a === void 0 ? void 0 : _a.period) === SubscriptionPeriod.None) {
                    console.log('Freemium user');
                    config.ads = { ...config.ads, ...(((_b = config.overrides) === null || _b === void 0 ? void 0 : _b.none) || {}) };
                }
                else {
                    config.ads = { ...config.ads, ...(((_c = config.overrides) === null || _c === void 0 ? void 0 : _c.paid) || {}) };
                    // console.log('config', config.overrides?.paid)
                    console.log('Paid user', config.ads);
                }
            }
            else {
                console.log('Normal user');
                config.ads = { ...config.ads, ...(((_d = config.overrides) === null || _d === void 0 ? void 0 : _d.user) || {}) };
            }
        }
        else {
            console.log('Guest user');
        }
        this.setAdsConfig(config);
    }
    setWelcomeFormConfig(config) {
        this.welcomeFormConfig = config;
    }
    setSiteImage(imageUrl) {
        this.siteImage = imageUrl;
    }
    setAbTestsConfig(config) {
        this.abTests = {
            ...this.abTests,
            ...config
        };
    }
    isAbTestEnabled() {
        return this.abTests.enabled;
    }
    setAbTestFromCookies(cookies) {
        const nameEQ = 'selectedAbTest=';
        const ca = cookies.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) {
                this.selectedAbTest = c.substring(nameEQ.length, c.length);
                break;
            }
        }
        if (this.isAbTestEnabled() && !this.selectedAbTest && typeof document !== 'undefined') {
            this.setRandomAbTest();
        }
    }
    getSelectedAbTest() {
        return this.selectedAbTest;
    }
    setRandomAbTest() {
        if (!this.isAbTestEnabled()) {
            return;
        }
        const random = Math.random();
        let sum = 0;
        for (const [test, ratio] of Object.entries(this.abTests.ratios)) {
            sum += ratio;
            if (random <= sum) {
                const date = new Date();
                date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7 days
                const expires = `expires=${date.toUTCString()}`;
                document.cookie = `selectedAbTest=${test};${expires};path=/`;
                break;
            }
        }
    }
};
SiteService = __decorate([
    Injectable()
], SiteService);
export { SiteService };
